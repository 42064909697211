<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: "App"
};
</script>
