<template>
	<div>
		<div v-if="testOverlay">
			<div style="margin-top: 50px; text-align: center;">
				<div class="test-loading">
					<div class="lds-css" style="width: 250px; height: 250px; margin: 0 auto;">
						<div class="lds-spinner" style="width: 100%; height: 100%;">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
				</div>
				<div style="font-size: 30px;">Загрузка...</div>
			</div>
		</div>
		<div v-else>
			<div class="content-header">ТЕСТИРОВАНИЕ</div>
			<div v-if="testStarted">
				<div class="content-header">Вопрос {{questionPos + 1}} из {{questionsLimit}}:</div>
				<div style="text-align: center;" v-if="userQuestions[questionPos].img">
					<img :src="userQuestions[questionPos].img" style="max-width: 90%; padding: 0 10px;" />
				</div>
				<div style="text-align: center;">{{userQuestions[questionPos].text}}</div>
				<div class="answers">
					<div class="answer" v-for="(answer, index) in userQuestions[questionPos].answers">
						<label>
							<input type="radio" v-model.number="answerPos" :value="index" />
							<span v-html="answer"></span>
						</label>
					</div>
				</div>
				<div class="controls">
					<div class="btn btn-exit" @click="finish()">Завершить</div>
					<div class="btn btn-next" @click="next()">
						<span v-if="answerPos == -1">Пропустить</span>
						<span v-else>Ответить</span>
					</div>
				</div>
			</div>
			<div v-else>
				<div v-if="showResult">
					<div class="content-header">РЕЗУЛЬТАТЫ:</div>
					<div style="text-align: center;">Всего вопросов: {{questionsLimit}}</div>
					<div style="text-align: center;">Из них правильных: {{questionsLimit - failedAnswers}}</div>
					<div style="text-align: center;">Из них НЕ правильных: {{failedAnswers}}</div>
					<div style="margin-top: 10px; text-align: center;" v-if="failedSections.length > 0">
						<div style="text-align: center;">Рекомендуется повторить следующие разделы:</div>
						<ul style="margin: 0;">
							<li v-for="fsect in failedSections">
								<router-link :to="'/material/'+getSection(fsect).id">{{getSection(fsect).name}}</router-link>
							</li>
						</ul>
					</div>
					<div style="margin-top: 40px; text-align: center;">
						<div class="btn btn-start" @click="start()">НАЧАТЬ СНОВА</div>
					</div>
					<div style="margin-top: 40px;">
						<div class="content-header">ВАШИ ОТВЕТЫ:</div>
						<div style="margin-bottom: 15px;" v-for="(uquestion, index) in userQuestions">
							<div style="text-align: center;">Вопрос {{index + 1}}:</div>
							<div style="text-align: center;" v-if="uquestion.img">
								<img :src="uquestion.img" style="max-width: 90%; padding: 0 10px;" />
							</div>
							<div style="text-align: center;">{{uquestion.text}}</div>
							<div v-if="userAnswers[index] == uquestion.correct">
								<div style="text-align: center;">Дан правильный ответ: "{{uquestion.answers[userAnswers[index]]}}"</div>
							</div>
							<div v-else>
								<div style="text-align: center;" v-if="userAnswers[index] != -1">Дан НЕ правильный ответ: "{{uquestion.answers[userAnswers[index]]}}"</div>
								<div style="text-align: center;" v-else>Ответ не дан</div>
								<div style="text-align: center;">А правильный ответ: "{{uquestion.answers[uquestion.correct]}}"</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else>
					<div style="margin-top: 30px; text-align: center;">{{questionsLimit}} случайных вопроса(-ов)<br/>из базы на {{questions.length}} вопрос(-a,-ов)<br/>по {{sections.length}} разделам</div>
					<div style="margin-top: 40px; text-align: center;">
						<div class="btn btn-start" @click="start()">НАЧАТЬ</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>
.answers {
	margin-top: 15px;
}
.answers .answer {
	margin-bottom: 10px;
}
.controls {
	margin-top: 30px;
}
.btn {
	display: inline-block;
	padding: 5px 10px;
	background: #fff;
	color: #000;
	cursor: pointer;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	-o-border-radius: 5px;
}
.btn.btn-start,
.btn.btn-next {
	background: #91a751;
	color: #fff;
}
.btn.btn-start:hover,
.btn.btn-next:hover {
	background: #5E6B34;
	color: #fff;
}
.btn.btn-next {
	float: right;
}
.btn.btn-exit {
	background: #999;
	color: #fff;
}
.btn.btn-exit:hover {
	background: #777;
	color: #fff;
}

@keyframes lds-spinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@-webkit-keyframes lds-spinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.lds-spinner {
	position: relative;
}
.lds-spinner div {
	left: 120px;
	top: 10px;
	position: absolute;
	-webkit-animation: lds-spinner linear 1s infinite;
	animation: lds-spinner linear 1s infinite;
	background: #000;
	width: 20px;
	height: 60px;
	border-radius: 20%;
	-webkit-transform-origin: 6px 115px;
	transform-origin: 6px 115px;
}
.lds-spinner div:nth-child(1) {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-animation-delay: -0.916666666666667s;
	animation-delay: -0.916666666666667s;
}
.lds-spinner div:nth-child(2) {
	-webkit-transform: rotate(30deg);
	transform: rotate(30deg);
	-webkit-animation-delay: -0.833333333333333s;
	animation-delay: -0.833333333333333s;
}
.lds-spinner div:nth-child(3) {
	-webkit-transform: rotate(60deg);
	transform: rotate(60deg);
	-webkit-animation-delay: -0.75s;
	animation-delay: -0.75s;
}
.lds-spinner div:nth-child(4) {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
	-webkit-animation-delay: -0.666666666666667s;
	animation-delay: -0.666666666666667s;
}
.lds-spinner div:nth-child(5) {
	-webkit-transform: rotate(120deg);
	transform: rotate(120deg);
	-webkit-animation-delay: -0.583333333333333s;
	animation-delay: -0.583333333333333s;
}
.lds-spinner div:nth-child(6) {
	-webkit-transform: rotate(150deg);
	transform: rotate(150deg);
	-webkit-animation-delay: -0.5s;
	animation-delay: -0.5s;
}
.lds-spinner div:nth-child(7) {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
	-webkit-animation-delay: -0.416666666666667s;
	animation-delay: -0.416666666666667s;
}
.lds-spinner div:nth-child(8) {
	-webkit-transform: rotate(210deg);
	transform: rotate(210deg);
	-webkit-animation-delay: -0.333333333333333s;
	animation-delay: -0.333333333333333s;
}
.lds-spinner div:nth-child(9) {
	-webkit-transform: rotate(240deg);
	transform: rotate(240deg);
	-webkit-animation-delay: -0.25s;
	animation-delay: -0.25s;
}
.lds-spinner div:nth-child(10) {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg);
	-webkit-animation-delay: -0.166666666666667s;
	animation-delay: -0.166666666666667s;
}
.lds-spinner div:nth-child(11) {
	-webkit-transform: rotate(300deg);
	transform: rotate(300deg);
	-webkit-animation-delay: -0.083333333333333s;
	animation-delay: -0.083333333333333s;
}
.lds-spinner div:nth-child(12) {
	-webkit-transform: rotate(330deg);
	transform: rotate(330deg);
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
	input[type=radio] {
		--active: #275EFE;
		--active-inner: #fff;
		--focus: 2px rgba(39, 94, 254, .3);
		--border: #BBC1E1;
		--border-hover: #275EFE;
		--background: #fff;
		--disabled: #F6F8FF;
		--disabled-inner: #E1E6F9;
		-webkit-appearance: none;
		-moz-appearance: none;
		height: 21px;
		outline: none;
		display: inline-block;
		vertical-align: top;
		position: relative;
		margin: 0;
		margin-top: 5px;
		cursor: pointer;
		border: 1px solid var(--bc, var(--border));
		background: var(--b, var(--background));
		transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
	}
	input[type=radio]:after {
		content: "";
		display: block;
		left: 0;
		top: 0;
		position: absolute;
		transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
	}
	input[type=radio]:checked {
		--b: var(--active);
		--bc: var(--active);
		--d-o: .3s;
		--d-t: .6s;
		--d-t-e: cubic-bezier(.2, .85, .32, 1.2);
	}
	input[type=radio]:disabled {
		--b: var(--disabled);
		cursor: not-allowed;
		opacity: 0.9;
	}
	input[type=radio]:disabled:checked {
		--b: var(--disabled-inner);
		--bc: var(--border);
	}
	input[type=radio]:disabled + label {
		cursor: not-allowed;
	}
	input[type=radio]:hover:not(:checked):not(:disabled) {
		--bc: var(--border-hover);
	}
	input[type=radio]:focus {
		box-shadow: 0 0 0 var(--focus);
	}
	input[type=radio]:not(.switch) {
		width: 21px;
	}
	input[type=radio]:not(.switch):after {
		opacity: var(--o, 0);
	}
	input[type=radio]:not(.switch):checked {
		--o: 1;
	}
	input[type=radio] + label {
		font-size: 14px;
		line-height: 21px;
		display: inline-block;
		vertical-align: top;
		cursor: pointer;
		margin-left: 4px;
	}
	
	input[type=radio] {
		border-radius: 50%;
	}
	input[type=radio]:after {
		width: 19px;
		height: 19px;
		border-radius: 50%;
		background: var(--active-inner);
		opacity: 0;
		transform: scale(var(--s, 0.7));
	}
	input[type=radio]:checked {
		--s: .5;
	}
}
</style>

<script>
export default {
	name: 'Test',
	props: ['namespace'],
	data: () => ({
		sections: [
			/*{id: 1, name: 'Первый раздел'},
			{id: 2, name: 'Второй раздел'},
			{id: 3, name: 'Третий раздел'},*/
		],
		questions: [
			/*{
				section_id: 1,
				text: 'Текст вопроса 1...',
				img: '//posobiepdd.bsrv.su/static/img/bg.jpg',
				answers: [
					'вариант отв 1',
					'вариант отв 2',
					'вариант отв 3',
				],
				correct: 0, // индекс правильного ответа
			},
			{
				section_id: 2,
				text: 'Текст вопроса 2...',
				img: '',
				answers: [
					'вариант 1',
					'вариант 2',
					'вариант 3',
				],
				correct: 0, // индекс правильного ответа
			},
			{
				section_id: 3,
				text: 'Текст вопроса 3...',
				img: '',
				answers: [
					'вариант ответа 1',
					'вариант ответа 2',
					'вариант ответа 3',
				],
				correct: 0, // индекс правильного ответа
			},*/
		],
		
		userQuestions: [], // вопросы выпавшие пользователю
		userAnswers: [], // ответы пользователя в формате <индекс вопроса>: <индекс ответа>
		failedSections: [], // разделы, в которых совершены ошибки в формате <id раздела>
		failedAnswers: 0, // количество неправильных ответов
		answerPos: -1, // выбранный вариант ответа
		questionPos: 0, // текущий вопрос (индекс массива userQuestions)
		
		questionsLimit: 20, // количество выдаваемых вопросов пользователю
		
		testOverlay: true,
		testStarted: false,
		showResult: false,
	}),
	methods: {
		getSection(id = null){
			if(id != null){
				for(let sect of this.sections){
					if(sect.id == id) return sect;
				}
			}
			return {};
		},
		loadSections(){
			this.testOverlay = true;
			fetch('/api/materials-list')
				.then(stream => stream.json())
				.then(data => {
					let sections = [];
					for(let elem of data.materials){
						if(!['1', '30'].includes(elem.id)){
							sections.push({
								id: elem.id,
								name: elem.head,
							});
						}
					}
					this.sections = sections;
				})
				.catch(error => console.error(error));
		},
		loadQuestions(){
			this.testOverlay = true;
			fetch('/api/test-questions-list')
				.then(stream => stream.json())
				.then(data => {
					this.questions = data.questions;
				})
				.catch(error => console.error(error));
		},
		load(){
			this.testOverlay = true;
			
			this.testStarted = false;
			this.showResult = false;
			
			this.loadSections();
			this.loadQuestions();
			
			setTimeout(() => {
				this.testOverlay = false;
			}, 500);
		},
		start(){
			this.userQuestions = [];
			this.userAnswers = [];
			this.failedSections = [];
			this.failedAnswers = 0;
			this.answerPos = -1;
			this.questionPos = 0;
			
			this.testStarted = true;
			this.showResult = false;
			
			// формируем список вопросов
			let arr = [];
			for(let i = 0; i < this.questionsLimit; i++){
				let val = null;
				do {
					val = this.mt_rand(0, this.questions.length);
				} while(arr.includes(val) && val >= this.questions.length);
				arr.push(val);
			}
			for(let index of arr){
				this.userQuestions.push(this.questions[index]);
			}
		},
		next(){
			if(this.answerPos != -1){
				this.userAnswers[this.questionPos] = this.answerPos;
				if(this.answerPos != this.userQuestions[this.questionPos].correct){
					this.failedAnswers++;
					if(!this.failedSections.includes(this.userQuestions[this.questionPos].section_id)){
						this.failedSections.push(this.userQuestions[this.questionPos].section_id);
					}
				}
				this.answerPos = -1;
			} else {
				this.userAnswers[this.questionPos] = -1;
				this.failedAnswers++;
				if(!this.failedSections.includes(this.userQuestions[this.questionPos].section_id)){
					this.failedSections.push(this.userQuestions[this.questionPos].section_id);
				}
			}
			if(this.questionPos < this.userQuestions.length - 1){
				this.questionPos++;
			} else {
				this.testStarted = false;
				this.showResult = true;
			}
		},
		finish(){
			if(this.answerPos != -1){
				this.userAnswers[this.questionPos] = this.answerPos;
				if(this.answerPos != this.userQuestions[this.questionPos].correct){
					this.failedAnswers++;
					if(!this.failedSections.includes(this.userQuestions[this.questionPos].section_id)){
						this.failedSections.push(this.userQuestions[this.questionPos].section_id);
					}
				}
				this.answerPos = -1;
				if(this.questionPos < this.userQuestions.length - 1){
					this.questionPos++;
				} else {
					this.testStarted = false;
					this.showResult = true;
				}
			}
			for(this.questionPos; this.questionPos < this.userQuestions.length; this.questionPos++){
				this.userAnswers[this.questionPos] = -1;
				this.failedAnswers++;
				if(!this.failedSections.includes(this.userQuestions[this.questionPos].section_id)){
					this.failedSections.push(this.userQuestions[this.questionPos].section_id);
				}
			}
			
			this.testStarted = false;
			this.showResult = true;
		},
		
		mt_rand(min, max){
			return Math.floor(Math.random() * (max - min) + min);
		},
	},
	beforeMount(){
		this.load();
	},
	 beforeRouteUpdate(to, from, next){
		next();
		this.load();
	},
	buildStore: (state) => {
		if(state == null || state == undefined){
			state = {};
		}
		var res = {
			namespaced: true,
			state: () => {
				return state;
			},
			modules: {},
			mutations: {},
		}
		return res;
	},
	computed: {
		model(){
			return this.getModel();
		},
		layout(){
			return this.$store.state.page.Layout
		},
	},
}
</script>