import Vue from 'vue';

import axios from 'axios';
window.axios = require('axios');
Vue.prototype.$http = axios;
const token = localStorage.getItem('token');
if(token){
	Vue.prototype.$http.defaults.headers.common['Authorization'] = token;
}

import Vuex from 'vuex';
Vue.use(Vuex);

import VueRouter from 'vue-router';
Vue.use(VueRouter);

import Store from './store/store.js';
Vue.use(Store);

//Vue.config.productionTip = false;
Vue.config.debug = true;
Vue.config.devtools = true;

import App from './App.vue';
import Router from './router.js';

var params = {
	render: h => h(App),
};
params = Store(params);
params = Router(params);
params = Store.init(params);

var app = new Vue(params).$mount('#app');

window["appInstance"] = app;
window["storeInstance"] = params["store"];
