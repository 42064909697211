<template>
	<div>
		<a href="https://polytech.kz" target="_blank" class="polytech-logo"></a>
		<div class="pdd-logo">
			<div>
				ПРАВИЛА ДОРОЖНОГО ДВИЖЕНИЯ<br/>
				учебно-методическое пособие
			</div>
		</div>
		<div class="content">
			<div class="content-left">
				<div class="content-header">Навигация</div>
				<div class="menu">
					<router-link custom :to="{path: '/material/'+item.id}" v-slot="{href, route, navigate, isActive, isExactActive}" v-for="item in menu">
						<a :class="['menu-item', {'active': isExactActive}]" :href="href" @click="navigate">{{item.head}}</a>
					</router-link>
					<router-link custom :to="{path: '/test'}" v-slot="{href, route, navigate, isActive, isExactActive}">
						<a :class="['menu-item', {'active': isExactActive}]" :href="href" @click="navigate">Тестирование</a>
					</router-link>
				</div>
			</div>
			<div class="content-right">
				<router-view/>
			</div>
		</div>
		<div class="footer">
			Разработал Бикадамов Даулетияр, гр. П-17-42Б в 2021 г.
		</div>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'Layout',
	props: ['namespace'],
	data: () => ({
		menu: [],
	}),
	buildStore: (state) => {
		if(state == null || state == undefined){
			state = {
				
			};
		}
		
		var res = {
			namespaced: true,
			state: () => {
				return state;
			},
			modules: {},
			mutations: {},
		}
		
		return res;
	},
	computed: {
		model(){
			return this.getModel();
		},
		layout(){
			return this.$store.state.page.Layout
		},
	},
	methods: {
		loadData(){
			var that = this;
			var store = this.$store;
			
			fetch('/api/materials-list').then(stream => stream.json()).then(data => {
				that.menu = data.materials;
			}).catch(error => {
				console.error(error);
			});
		},
	},
	created(){
		this.loadData();
	},
}
</script>
