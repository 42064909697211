import Vue from 'vue';
import VueRouter from 'vue-router';

import Layout from './layouts/Layout.vue';
import Material from './pages/Material.vue';
import Test from './pages/Test.vue';
import PageNotFound from './pages/PageNotFound.vue';

export default function(params)
{
	params["router"] = new VueRouter({
		mode: 'history',
		base: '/',
		routes:
		[
			{
				path: '/',
				redirect: '/material/1',
			},
			{
				path: '/',
				component: Layout,
				children: [
					{
						path: '/material',
						redirect: '/material/1',
					},
					{
						path: '/material/:id',
						component: Material,
					},
					{
						path: '/test',
						component: Test,
					},
					{
						path: '*',
						component: PageNotFound,
					},
				],
				props: { default: true, namespace: ["page", "Home"] }
			},
		],
		//linkActiveClass: 'active',
		//linkExactActiveClass: 'active',
	});
	
	params["store"].registerModule(["page", "Layout"], Layout.buildStore());
	params["store"].registerModule(["page", "Material"], Material.buildStore());
	params["store"].registerModule(["page", "Test"], Test.buildStore());
	
	return params;
}
